import { FluentButton } from 'owa-fluent-v9-shims';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import classnames from 'owa-classnames';
import type { IIconProps } from '@fluentui/react/lib/Icon';
import { Icon } from '@fluentui/react/lib/Icon';
import React from 'react';
import { touchHandler } from 'owa-touch-handler';
import type { HoverActionIconProps, PropertyIconProps } from 'owa-mail-list-item-shared';
import { disabledHoverActionCursor } from 'owa-mail-listitem-styles/lib/scss/IconBar.scss';
import { makeStyles } from '@fluentui/react-components';
import { iconFilledClassName, iconRegularClassName } from '@fluentui/react-icons';
import { mergeStyles } from '@fluentui/merge-styles';
import { isFeatureEnabled } from 'owa-feature-flags';

export interface IconBarProps {
    hoverActionIcons: HoverActionIconProps[];
    propertyIcons: PropertyIconProps[];
    iconClass: string;
    propertyIconClass: string;
    hoverIconClass: string;
}

const useStyles = makeStyles({
    icon: {
        [`& .${iconFilledClassName}`]: {
            display: 'none',
        },
        [`& .${iconRegularClassName}`]: {
            display: 'inline',
        },
        ':hover': {
            [`& .${iconFilledClassName}`]: {
                display: 'inline',
            },
            [`& .${iconRegularClassName}`]: {
                display: 'none',
            },
        },
    },
});

export const PropertyIcon = React.memo(function PropertyIcon({
    iconClasses,
    iconName,
    id,
}: PropertyIconProps) {
    return <Icon id={id} className={iconClasses} iconName={iconName} />;
});

export const HoverIcon = React.memo(function HoverIcon(props: HoverActionIconProps) {
    const useBundleIcons = isFeatureEnabled('tri-mlRelocateHoverActions');
    const iconStyles = useStyles();
    const iconProps: IIconProps = useComputedValue(() => {
        return {
            iconName: props.iconName,
            className: useBundleIcons
                ? mergeStyles(iconStyles.icon, props.iconClassName)
                : props.iconClassName,
        };
    }, [props.iconName, props.iconClassName]);

    const buttonStyles = useComputedValue(
        () => ({
            root: classnames(
                props.iconClasses,
                props.iconName == 'None' && disabledHoverActionCursor
            ),
        }),
        [props.iconClasses]
    );

    const longPress = props.onContextMenu
        ? touchHandler({
              onLongPress: props.onContextMenu,
          })
        : null;
    const onContextMenuHandler = React.useCallback(
        (evt: React.MouseEvent<unknown>) => {
            if (props.onContextMenu) {
                props.onContextMenu(evt);
            }
        },
        [props.onContextMenu]
    );

    const onClickHandler = React.useCallback(
        (evt: React.MouseEvent<unknown>) => {
            evt.stopPropagation();
            props.onClickCommand?.();
        },
        [props.onClickCommand]
    );

    const onDoubleClickHandler = React.useCallback((evt: React.MouseEvent<unknown>) => {
        evt.stopPropagation();
    }, []);
    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const onRenderMenuIconHandler = () => null;
    return (
        <FluentButton
            appearance="icon"
            tabIndex={-1}
            iconProps={iconProps}
            onClick={onClickHandler}
            onDoubleClick={onDoubleClickHandler}
            onRenderMenuIcon={onRenderMenuIconHandler}
            styles={buttonStyles}
            title={props.title}
            aria-label={props.title}
            menuProps={props.menuProps}
            onContextMenu={onContextMenuHandler}
            {...longPress}
        />
    );
});
